import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const NavigationContainer = styled.div`
  height: 70px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin: 15px 0 30px;
`;

export const LogoContainer = styled(Link)`
  height: 100%;
  width: 70px;
`;

export const StoreNameContainer = styled.div`
  width: 50%;
  font-size: 20pt;
  vertical-align: bottom;
  height: 100%;
  line-height: 80px;
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;
`;

export const NavLinks = styled.div`
  width: 30%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

export const NavLink = styled(Link)`
  padding: 10px 15px;
  cursor: pointer;
`;
